import React, { useContext, useState } from 'react'
import {
  Chip,
  FormControlLabel,
  ListItemButton,
  Rating,
  Switch,
} from '@mui/material'

import CustomSuspense from './custom-suspense'
import { utils } from '@/utils'
import { WeekBodyContext } from '@/contexts/week-body'

import type { CalendarResItem } from '@/api/calendar/types'

interface Props {
  onCardClick?: (card: CalendarResItem) => void
}

const WeekCard = (props: Props) => {
  const { onCardClick } = props
  const { calendarData, isInitialLoading, isRefetching } =
    useContext(WeekBodyContext)!
  const [onlyImportant, setOnlyImportant] = useState(true)

  const list = calendarData?.list ?? []
  const filteredList = onlyImportant ? list.filter((i) => i.is_important) : list

  return (
    <>
      <FormControlLabel
        value={onlyImportant}
        checked={onlyImportant}
        onChange={() => setOnlyImportant(!onlyImportant)}
        control={<Switch />}
        label="只看重要"
        className="!ml-1"
      />
      <CustomSuspense
        isInitial={isInitialLoading}
        isRefetching={isRefetching}
        fallback={
          <p className="text-sm text-gray-400 text-center py-2">加载中...</p>
        }
        nullback={
          <p className="text-sm text-gray-400 text-center py-2">本日暂无事件</p>
        }
      >
        {filteredList.map((c) => (
          <ListItemButton
            key={c.id}
            classes={{
              root: '!block !bg-gray-100 !m-2 !p-2 !rounded-md',
            }}
            onClick={() => onCardClick?.(c)}
          >
            <div>
              <h3 className="my-0 inline-flex items-end break-all">
                {utils.withNull(c.title)}
                {!!c.is_important && (
                  <Rating max={1} value={c.is_important} className="ml-2" />
                )}
              </h3>
              <div className="text-gray-400 text-sm">{c.published_time}</div>
            </div>
            <div className="my-3">
              {c.route_map_category && (
                <Chip
                  key={c.route_map_category.id}
                  size="small"
                  variant="outlined"
                  color="primary"
                  className="!rounded mr-1"
                  label={c.route_map_category.name}
                />
              )}
              {c.token.slice(0, 3).map((t) => (
                <Chip
                  key={t.id}
                  size="small"
                  color="primary"
                  className="!rounded mr-1"
                  label={t.symbol}
                />
              ))}
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: utils.withNull(c.content) }}
            ></div>
          </ListItemButton>
        ))}
      </CustomSuspense>
    </>
  )
}

export default WeekCard
