import React, { useContext } from 'react'
import { Button, IconButton } from '@mui/material'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'

import WeekPicker from './week-picker'
import { useResponsive } from '@/hooks/use-responsive'
import { HomeContext } from '@/contexts/home'

import type { WeekItem } from '@/hooks/use-week'
import type { Dayjs } from 'dayjs'

const WeekList = () => {
  const {
    currentWeek,
    currentDay,
    setCurrentDay,
    prevWeek,
    nextWeek,
    setAWeek,
    resetWeekAndDay,
  } = useContext(HomeContext)!
  const { isMobile } = useResponsive()

  const computedClass = (day: WeekItem) => {
    const classes: string[] = []

    const addColor = (str: string) => {
      const colorIdx = classes.findIndex((c) => c === '!text-black')

      classes.splice(colorIdx, 1)
      classes.push(str)
    }

    // text color
    classes.push(day.isRestDay ? '!text-gray-500' : '!text-black')

    // today color
    if (day.isToday) {
      addColor('!bg-gray-200 !text-black')
    }

    // active color
    if (day.dayNum === currentDay.day()) {
      addColor('!bg-primary !text-white hover:!bg-primary')
    }

    return classes.join(' ')
  }

  const onDayClick = (day: WeekItem) => {
    setCurrentDay(day.dayjs)
  }

  const onWeekChange = (day: Dayjs | null) => {
    if (!day) return

    setCurrentDay(day)
    setAWeek(day.startOf('week'), day.endOf('week'))
  }

  return (
    <div className="bg-white sticky top-0 z-50 px-4 pb-4 max-sm:px-2 max-sm:w-full ">
      <div className="flex pt-6 w-full">
        <WeekPicker
          label="选择日期"
          value={currentDay}
          onChange={onWeekChange}
        />
        <Button
          variant="outlined"
          classes={{ root: '!ml-4 max-sm:!ml-2 shrink-0' }}
          onClick={resetWeekAndDay}
        >
          回到今日
        </Button>
        {isMobile && (
          <div className="flex-1 flex justify-between items-center shrink-0 ml-1">
            <IconButton classes={{ root: '!p-1' }} onClick={prevWeek}>
              <MdChevronLeft className="shrink-0" size={28} />
            </IconButton>
            <IconButton classes={{ root: '!p-1' }} onClick={nextWeek}>
              <MdChevronRight className="shrink-0" size={28} />
            </IconButton>
          </div>
        )}
      </div>
      <div className="mt-4 flex justify-between items-center max-sm:mt-2 w-full">
        {!isMobile && (
          <IconButton
            classes={{ root: 'w-16 h-16 max-sm:w-4 max-sm:h-4 max-sm:!p-' }}
            onClick={prevWeek}
          >
            <MdChevronLeft className="shrink-0" size={20} />
            <span className="text-sm shrink-0 max-sm:hidden">上周</span>
          </IconButton>
        )}
        <div className="flex-1 flex justify-evenly items-center">
          {currentWeek.map((w) => (
            <IconButton
              key={w.dayNum}
              className={`w-16 h-16 flex-col max-sm:w-12 max-sm:h-12 ${computedClass(
                w
              )}`}
              onClick={() => onDayClick(w)}
            >
              <span className="max-sm:text-base">{w.date}</span>
              <span className="text-sm max-sm:text-xs">{w.day}</span>
            </IconButton>
          ))}
        </div>
        {!isMobile && (
          <IconButton
            classes={{
              root: 'w-16 h-16 max-sm:w-4 max-sm:h-4 max-sm:!p- shrink-0',
            }}
            onClick={nextWeek}
          >
            <span className="text-sm shrink-0 max-sm:hidden">下周</span>
            <MdChevronRight className="shrink-0" size={20} />
          </IconButton>
        )}
      </div>
    </div>
  )
}

export default WeekList
