import React, { useContext, useState } from 'react'
import { useQuery } from '@tanstack/react-query'

import DetailDialog from './detail-dialog'
import WeekTable from './week-table'
import WeekCard from './week-card'
import { calendarApi } from '@/api/calendar'
import { useResponsive } from '@/hooks/use-responsive'
import { HomeContext } from '@/contexts/home'
import { WeekBodyContext } from '@/contexts/week-body'
import { utils } from '@/utils'

const WeekBody = () => {
  const [detailId, setDetailId] = useState<number>()
  const { currentDay } = useContext(HomeContext)!
  const { isMobile } = useResponsive()
  const {
    data: calendarData,
    isLoading: isInitialLoading,
    isRefetching,
  } = useQuery({
    queryKey: [calendarApi.calendarList.name, currentDay],
    queryFn: () => {
      return calendarApi.calendarList({
        start_time: currentDay.startOf('day').unix(),
        end_time: currentDay.endOf('day').unix(),
      })
    },
  })

  // 点击一个事件
  const onEventClick = (
    c: NonNullable<typeof calendarData>['list'][number]
  ) => {
    if (utils.isNull(c.content)) return

    setDetailId(c.id)
  }

  return (
    <WeekBodyContext.Provider
      value={{ calendarData, isInitialLoading, isRefetching, setDetailId }}
    >
      <DetailDialog id={detailId} onClose={() => setDetailId(undefined)} />
      {isMobile ? (
        <WeekCard onCardClick={onEventClick} />
      ) : (
        <WeekTable onRowClick={onEventClick} />
      )}
    </WeekBodyContext.Provider>
  )
}

export default WeekBody
