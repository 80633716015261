import React from 'react'

import Layout from '@/components/layout'
import WeekList from '@/components/week-list'
import WeekBody from '@/components/week-body'
import { useWeek } from '@/hooks/use-week'
import { HomeContext } from '@/contexts/home'

export default function Home() {
  const useWeekReturn = useWeek()

  return (
    <Layout mainClass="max-sm:min-h-screen">
      <HomeContext.Provider value={useWeekReturn}>
        <WeekList />
        <WeekBody />
      </HomeContext.Provider>
    </Layout>
  )
}
