import { api } from '../fetch'

import type {
  CalendarReq,
  CalendarRes,
  CalendarEnumReq,
  CalendarEnumRes,
  CalendarDetailRes,
} from './types'

export const calendarApi = {
  // 不区分 VIP
  calendarList(query: CalendarReq) {
    return api.get<CalendarRes>('/trend/calendar/query', query)
  },
  // 区分 VIP
  calendarEnum(query: CalendarEnumReq) {
    return api.get<CalendarEnumRes>('/trend/calendar/enumerate', query)
  },
  calendarDetail(id: number | string) {
    return api.get<CalendarDetailRes>('/trend/calendar/detail', { id })
  },
}
