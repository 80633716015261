import { createContext } from 'react'

import type { CalendarRes } from '@/api/calendar/types'

export const WeekBodyContext = createContext<{
  calendarData: CalendarRes | undefined
  isInitialLoading: boolean
  isRefetching: boolean
  setDetailId: React.Dispatch<React.SetStateAction<number | undefined>>
} | null>(null)
