import qs from 'querystring'

interface BaseRes<T> {
  status: number
  message: string
  data: T
}

interface Options extends Omit<RequestInit, 'body'> {
  body?: object | undefined | null
}

class Fetcher {
  baseURL: string
  extract: boolean

  constructor(baseURL: string, extract = true) {
    this.baseURL = baseURL
    this.extract = extract
  }

  async fetcher<T>(path: string, options?: Options) {
    const response = await fetch(`${this.baseURL}${path}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      ...options,
      body: options?.body ? JSON.stringify(options?.body) : null,
    })

    if (!response.ok) {
      return Promise.reject('Error')
    }

    const result = (await response.json()) as BaseRes<T>

    if (this.extract) return result.data

    return result as T
  }

  get<T, Q extends object = {}>(path: string, query?: Q) {
    return this.fetcher<T>(`${path}?${qs.stringify(query ?? {})}`, {
      method: 'GET',
    })
  }

  post<T, B = any>(path: string, body?: Record<string, B>) {
    return this.fetcher<T>(path, {
      method: 'POST',
      body,
    })
  }

  put<T, B = any>(path: string, body?: Record<string, B>) {
    return this.fetcher<T>(path, {
      method: 'PUT',
      body,
    })
  }

  delete<T, B = any>(path: string, body?: Record<string, B>) {
    return this.fetcher<T>(path, {
      method: 'DELETE',
      body,
    })
  }
}

export const api = new Fetcher('https://api.coregem.net')
