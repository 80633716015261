import dayjs, { type Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'

export interface WeekItem {
  dayjs: Dayjs
  date: string
  day: string
  dayNum: number
  isRestDay: boolean
  isToday: boolean
}

export const useWeek = () => {
  const [currentDay, setCurrentDay] = useState(dayjs())
  const [currentWeek, setCurrentWeek] = useState<WeekItem[]>([])

  const isRestday = (d: Dayjs) => d.day() === 0 || d.day() === 6

  // 获取一个区间的一周
  const getAWeek = (start?: Dayjs, end?: Dayjs) => {
    const startWeek = start ?? dayjs().startOf('week')
    const endWeek = end ?? dayjs().endOf('week')
    const datesAndDays = []
    let date = startWeek

    while (date.isSameOrBefore(endWeek, 'day')) {
      datesAndDays.push({
        dayjs: date,
        date: date.format('D'),
        day: date.format('dddd'),
        dayNum: date.day(),
        isRestDay: isRestday(date),
        isToday: dayjs().isSame(date, 'day'),
      })
      date = date.add(1, 'day')
    }

    return datesAndDays
  }

  // 设置一个指定的周
  const setAWeek = (start?: Dayjs, end?: Dayjs) => {
    const week = getAWeek(start, end)

    setCurrentWeek(week)
  }

  // 根据当前周切换到上一周
  const prevWeek = () => {
    const first = currentWeek[0].dayjs
    const prevWeek = getAWeek(
      first.subtract(1, 'week'),
      first.subtract(1, 'day')
    )
    const activeDay = prevWeek[currentDay.day()]

    setCurrentWeek(prevWeek)
    setCurrentDay(activeDay.dayjs)

    return prevWeek
  }

  // 根据当前周切换到下一周
  const nextWeek = () => {
    const last = currentWeek[currentWeek.length - 1].dayjs
    const nextWeek = getAWeek(last.add(1, 'day'), last.add(1, 'week'))
    const activeDay = nextWeek[currentDay.day()]

    setCurrentWeek(nextWeek)
    setCurrentDay(activeDay.dayjs)

    return nextWeek
  }

  // 重置为本周
  const resetWeekAndDay = () => {
    setCurrentWeek(getAWeek())
    setCurrentDay(dayjs())
  }

  // 初始为本周
  useEffect(() => {
    setCurrentWeek(getAWeek())
  }, [])

  return {
    currentDay,
    currentWeek,
    setCurrentDay,
    getAWeek,
    setAWeek,
    prevWeek,
    nextWeek,
    resetWeekAndDay,
  }
}
