import React, { useContext, useState } from 'react'
import {
  Chip,
  FormControlLabel,
  Paper,
  Rating,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import sanitizeHtml from 'sanitize-html'

import { utils } from '@/utils'

import CustomSuspense from './custom-suspense'
import { WeekBodyContext } from '@/contexts/week-body'

import type { CalendarResItem } from '@/api/calendar/types'

interface Props {
  onRowClick?: (c: CalendarResItem) => void
}

const thRows = ['事件', '重要', '标签', '相关代币', '详情']

const WeekTable = (props: Props) => {
  const { onRowClick } = props
  const [onlyImportant, setOnlyImportant] = useState(true)
  const { calendarData, isInitialLoading, isRefetching } =
    useContext(WeekBodyContext)!

  const list = calendarData?.list ?? []
  const filteredList = onlyImportant
    ? list.filter((item) => item.is_important)
    : list

  const htmlTagOptions = {
    allowedTags: [], // 不允许的标签
    allowedAttributes: {}, // 不允许的属性
  }

  return (
    <>
      <FormControlLabel
        value={onlyImportant}
        checked={onlyImportant}
        onChange={() => setOnlyImportant(!onlyImportant)}
        control={<Switch />}
        label="只看重要"
        className="!ml-2"
      />
      <TableContainer component={Paper} classes={{ root: '!shadow-none my-4' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {thRows.map((r, i) => (
                <TableCell
                  key={i}
                  classes={{ root: 'text-gray-500 whitespace-nowrap' }}
                >
                  {r}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <CustomSuspense
              customRoot={(children) => <>{children}</>}
              isInitial={isInitialLoading}
              isRefetching={isRefetching}
              fallback={
                <tr>
                  <td
                    colSpan={6}
                    className="text-sm text-gray-400 text-center py-10"
                  >
                    加载中...
                  </td>
                </tr>
              }
              nullback={
                <tr>
                  <td
                    colSpan={6}
                    className="text-sm text-gray-400 text-center py-10"
                  >
                    本日暂无事件
                  </td>
                </tr>
              }
            >
              {filteredList.map((r) => (
                <TableRow
                  key={r.id}
                  hover
                  className="cursor-pointer"
                  onClick={() => onRowClick?.(r)}
                >
                  <TableCell>{utils.withNull(r.title)}</TableCell>
                  <TableCell>
                    <Rating max={1} value={r.is_important} readOnly />
                  </TableCell>
                  <TableCell>
                    {r.route_map_category ? (
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        className="!rounded"
                        label={r.route_map_category?.name}
                      />
                    ) : (
                      <>无</>
                    )}
                  </TableCell>
                  <TableCell>
                    {utils.isNotEmpty(r.token) ? (
                      r.token
                        .slice(0, 3)
                        .map((t) => (
                          <Chip
                            key={t.id}
                            color="primary"
                            size="small"
                            classes={{ root: '!rounded mr-1 mt-1' }}
                            label={`${t.symbol}`}
                          />
                        ))
                    ) : (
                      <>无</>
                    )}
                    {r.token.length > 3 && <>...</>}
                  </TableCell>
                  <TableCell>
                    {sanitizeHtml(utils.withNull(r.content), htmlTagOptions)}
                  </TableCell>
                </TableRow>
              ))}
            </CustomSuspense>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default WeekTable
