interface WithNullParams {
  zh?: string
  en?: string
}

export const utils = {
  withNull(params?: WithNullParams, defaultContent = '无') {
    return params?.zh || params?.en || defaultContent
  },
  isNull(params?: WithNullParams) {
    return utils.withNull(params) === '无'
  },
  isEmpty<T extends Array<any>>(arr?: T) {
    return arr?.length === 0
  },
  isNotEmpty<T extends Array<any>>(arr?: T) {
    return (arr?.length ?? 0) > 0
  },
}
