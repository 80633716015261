import React from 'react'
import { Chip, Dialog, DialogContent, DialogTitle } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import { calendarApi } from '@/api/calendar'
import { utils } from '@/utils'

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'id'> {
  id?: number
  onClose?: () => void
}

const DetailDialog = (props: Props) => {
  const { id, onClose } = props
  const { data: detail } = useQuery({
    queryKey: [calendarApi.calendarDetail.name, id],
    queryFn: () => {
      if (!id) return Promise.resolve(null)

      return calendarApi.calendarDetail(id)
    },
  })

  return (
    <Dialog open={!!id} onClose={onClose} fullWidth>
      <DialogTitle classes={{ root: '!flex !flex-col !pb-2' }}>
        <span>{utils.withNull(detail?.title)}</span>
        <div className="text-gray-400 text-sm">
          <span className="mr-4">发布时间：{detail?.published_time}</span>
          <span className="text-gray-400 text-sm max-sm:block">
            预估时间：{detail?.estimated_time}
          </span>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="mb-4">
          {detail?.token.map((t) => (
            <Chip
              key={t.id}
              variant="filled"
              classes={{ root: '!rounded mr-1' }}
              color="primary"
              size="small"
              label={t.symbol}
            />
          ))}
        </div>
        {/* 内容 */}
        <div
          className="html-content-container"
          dangerouslySetInnerHTML={{ __html: utils.withNull(detail?.content) }}
        ></div>
        {/* 点评 */}
        {utils.isNotEmpty(detail?.review) && (
          <div>
            <div className="text-gray-400 text-sm my-4">点评:</div>
            <div>
              {detail?.review.map((r) => (
                <div key={r.id}>
                  <span className="font-bold">
                    {r.user.name}
                    <span className="text-sm text-gray-400">
                      ({r.created_at})
                    </span>
                    :
                  </span>
                  <div
                    className="text-gray-500 "
                    dangerouslySetInnerHTML={{ __html: r.content }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default DetailDialog
