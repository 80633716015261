import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import {
  DatePicker,
  PickersDay,
  type PickersDayProps,
  type DatePickerProps,
} from '@mui/x-date-pickers'
import { type Dayjs } from 'dayjs'

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
  isSelected: boolean
  isHovered: boolean
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})<CustomPickerDayProps>(({ theme, isSelected, isHovered, day }) => ({
  borderRadius: 0,
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(isHovered && {
    backgroundColor: theme.palette.primary[theme.palette.mode],
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary[theme.palette.mode],
    },
  }),
  ...(day.day() === 0 && {
    borderTopLeftRadius: '15%',
    borderBottomLeftRadius: '15%',
  }),
  ...(day.day() === 6 && {
    borderTopRightRadius: '15%',
    borderBottomRightRadius: '15%',
  }),
})) as React.ComponentType<CustomPickerDayProps>

const isInSameWeek = (dayA: Dayjs, dayB: Dayjs | null | undefined) => {
  if (dayB == null) {
    return false
  }

  return dayA.isSame(dayB, 'week')
}

function Day(
  props: PickersDayProps<Dayjs> & {
    selectedDay?: Dayjs | null
    hoveredDay?: Dayjs | null
  }
) {
  const { day, selectedDay, hoveredDay, ...other } = props

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isSelected={isInSameWeek(day, selectedDay)}
      isHovered={isInSameWeek(day, hoveredDay)}
    />
  )
}

const StyledDatePicker = styled(DatePicker)({
  '& .MuiInputBase-input': {
    padding: '8px 14px',
  },
  '& .MuiFormLabel-root': {
    overflow: 'visible',
    lineHeight: '0.6',
  },
}) as React.ComponentType<DatePickerProps<Dayjs>>

interface Props {
  value: Dayjs | null | undefined
  label?: string
  className?: string
  onChange?: (day: Dayjs | null) => void
}

const WeekPicker = (props: Props) => {
  const { value, label, className, onChange } = props
  const [hoveredDay, setHoveredDay] = useState<Dayjs | null>(null)

  return (
    <StyledDatePicker
      className={className}
      value={value}
      label={label}
      onChange={onChange}
      showDaysOutsideCurrentMonth
      slots={{ day: Day }}
      slotProps={{
        day: (ownerState) => ({
          selectedDay: value,
          hoveredDay,
          onPointerEnter: () => setHoveredDay(ownerState.day),
          onPointerLeave: () => setHoveredDay(null),
        }),
      }}
    />
  )
}

export default WeekPicker
